import React from "react";
import PropTypes from "prop-types";
import { DuButton, DuActivityIndicator } from "components";
import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { ErrorMessage } from "./PaymentError";

const PaymentForm = ({
  paymentMethods,
  submitOrder,
  isPending,
  error,
  walletButtons,
  walletButtonError,
  useStripeButtons,
}) => {
  return (
    <div>
      {useStripeButtons && (
        <>
          <PaymentRequestButtonElement options={walletButtons} />
          {walletButtonError && (
            <div className="text-red-600 pt-2">
              <ErrorMessage error={walletButtonError} />
            </div>
          )}
        </>
      )}
      {isPending ? (
        <div className="flex w-full justify-center pt-8">
          <DuActivityIndicator local color="var(--primary-600)" />
        </div>
      ) : (
        paymentMethods.map((pm, idx) => (
          <div key={idx} className="pt-2">
            <DuButton wide onClick={() => submitOrder(pm.identifier)}>
              {pm.buttonText}
            </DuButton>
          </div>
        ))
      )}
      {error && (
        <div className="text-red-600 pt-2">
          <ErrorMessage error={error} />
        </div>
      )}
    </div>
  );
};

PaymentForm.propTypes = {
  paymentMethods: PropTypes.array.isRequired,
  submitOrder: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
  error: PropTypes.string,
  walletButtons: PropTypes.object,
  walletButtonError: PropTypes.string,
  useStripeButtons: PropTypes.bool,
};

export default PaymentForm;

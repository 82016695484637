import React from "react";
import PropTypes from "prop-types";

DuTextField.propTypes = {
  onTextChange: PropTypes.func,
  label: PropTypes.node,
};

export function DuTextField(props) {
  return (
    <div className="">
      <label
        htmlFor="about"
        className="block text-sm font-medium text-gray-700"
      >
        {props.label}
      </label>
      <div className="mt-1 relative rounded-md ">
        <textarea
          id="about"
          name="about"
          rows={3}
          className="shadow-sm focus:ring-primary-500 focus:border-primary-500
 block w-full sm:text-sm border-gray-300 rounded-md"
          defaultValue={""}
          onChange={(e) =>
            props.onTextChange && props.onTextChange(e.target.value)
          }
        />
        <p className="mt-2 text-sm text-gray-500"></p>
      </div>
    </div>
  );
}

import React from "react";
import PropTypes from "prop-types";

import { DuCounter, DuToggle, DuCurrencyFormat, DuInfo } from "components";
import { createValidOrderSubsectionsFromMenuSection } from "@dineup/businesslogic/build/src";
import { classNames } from "util/index";

function ExtraItem({ itemTuple: { order, menu }, onChange, isFirst, isLast }) {
  const itemTuple = { order: order ?? { amount: 0 }, menu };

  const onCounterChangeReq = (val) => {
    onChange({
      amount: val,
      sections: menu.sections
        ? itemTuple.order.sections ||
          createValidOrderSubsectionsFromMenuSection(menu.sections)
        : undefined,
    });
  };

  return (
    <div
      className={classNames(
        "flex justify-start items-center",
        "w-full px-4 py-3 border border-gray-200 bg-white text-sm ",
        isFirst ? "rounded-tl-md rounded-tr-md pt-4" : "",
        isLast ? "rounded-bl-md rounded-br-md pb-4" : ""
      )}
    >
      <div className="pr-4">
        {menu.asToggle ? (
          <DuToggle
            checked={itemTuple.order.amount === 1}
            onClick={() => onCounterChangeReq(1 - itemTuple.order.amount)}
          />
        ) : (
          <DuCounter
            tiny
            value={itemTuple.order.amount}
            onChangeRequest={onCounterChangeReq}
          />
        )}
      </div>
      <div className="font-medium text-gray-900 flex-1">{menu.name}</div>
      <DuInfo info={menu.info} />
      <div className="pl-2">
        {!!menu.price && <DuCurrencyFormat value={menu.price} plus />}
      </div>
    </div>
  );
}

ExtraItem.propTypes = {
  itemTuple: PropTypes.object.isRequired,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default ExtraItem;

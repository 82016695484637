import React from "react";
import PropTypes from "prop-types";
import { DuActivityIndicator } from "../DuActivityIndicator/DuActivityIndicator";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function DuButton({
  label,
  children,
  onClick,
  className = "",
  disabled,
  loading,
  secondary,
  bg,
  wide,
  ...rest
}) {
  const bgColor = bg || (disabled || secondary ? "bg-white" : "bg-primary-600"),
    text = disabled
      ? "text-gray-500"
      : secondary
      ? "text-gray-700"
      : "text-white",
    hover =
      disabled || loading
        ? ""
        : secondary
        ? "hover:bg-gray-100"
        : "hover:bg-primary-700",
    focus = disabled || loading ? "" : "focus:ring-primary-500",
    border = disabled || secondary ? "border-gray-300" : "border-transparent",
    width = wide ? "w-full flex flex-row justify-center" : "",
    isClickDisabled = disabled || loading;

  return (
    <button
      disabled={disabled}
      onClick={isClickDisabled ? undefined : onClick}
      type="button"
      className={classNames(
        "relative inline-flex h-12 items-center px-4 py-2 border",
        "text-sm font-medium rounded-md shadow-sm",
        "focus:outline-none focus:ring-2 focus:ring-offset-2",
        bgColor,
        text,
        hover,
        focus,
        border,
        width,
        className
      )}
      {...rest}
    >
      {loading && (
        <div className="absolute left-0 right-0 flex justify-center ">
          <DuActivityIndicator local className="" />
        </div>
      )}
      <div className={classNames(loading ? "opacity-0" : "", "w-full")}>
        {label || children}
      </div>
    </button>
  );
}

DuButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  bg: PropTypes.string,
  secondary: PropTypes.bool,
  wide: PropTypes.bool,
};

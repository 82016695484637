import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { get } from "util/api";
import { useSearch } from "util/hooks";
import { saveVenueConfig } from "redux/venueStore/actions";
import { resetOrder } from "redux/orderStore/actions";
import { DuActivityIndicator } from "components";

const _DetectVenueCode = ({ saveVenueConfig, resetOrder }) => {
  const history = useHistory();
  const {
    b: bgColor = "#fff",
    s: spinnerColor = "#000",
    i: nextBgImg = "",
  } = useSearch();
  useEffect(() => {
    const [, , code] = location.pathname.split("/");
    fetchVenueConfig(code);
  }, []);

  const fetchVenueConfig = async (code) => {
    try {
      const response = await get("user/venue/$1", [code]).on(
        { status: 404, error: "Venue not found" },
        () => {
          alert("Diesen QR-Code konnten wir leider nicht zuordnen.");
        }
      );
      saveVenueConfig(response);
      resetOrder();

      history.push("/venue-welcome");
    } catch (e) {
      // If e is not false, then no error-catcher caught the error and
      // you might want to take care of it
      e && alert(e);
      history.push("/");
    }
  };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: bgColor }}>
      <DuActivityIndicator color={spinnerColor} />
      <img className="hidden" src={nextBgImg} />
    </div>
  );
};
_DetectVenueCode.propTypes = {
  saveVenueConfig: PropTypes.func.isRequired,
  resetOrder: PropTypes.func.isRequired,
};
export const DetectVenueCode = connect(() => ({}), {
  saveVenueConfig,
  resetOrder,
})(_DetectVenueCode);

import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";

export const MenuDishOverlay = ({ setOpen, open, children }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden z-40"
        open={open}
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden ">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pt-0 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col py-6  shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between"></div>
                  </div>
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

MenuDishOverlay.propTypes = {
  setOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { DuCurrencyFormat } from "components/DuCurrencyFormat";

const _MenuIndexDish = ({ dish, stepIdx, sectionIdx, dishIdx }) => {
  const history = useHistory();
  const setShowDishDetails = () => {
    // setCurrentItem(stepIdx, sectionIdx, dishIdx, dish);
    history.push(`/dish/${stepIdx}/${sectionIdx}/${dishIdx}`);
  };

  return (
    <div
      onClick={() => setShowDishDetails(true)}
      className="relative z-0 w-full py-0"
    >
      <div className="flex bg-white ">
        <div className="flex-1 p-4">
          <h1 className="text-gray-900 font-bold text-l">{dish.name}</h1>
          {dish.description && (
            <p
              className="mt-2 text-gray-600 text-sm max-h-10
    overflow-hidden overflow-ellipis"
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }}
            >
              {dish.description}
            </p>
          )}
        </div>
        <div
          className="flex self-center mt-3
    min-width-20 px-4"
        >
          {!!dish.price && <DuCurrencyFormat value={dish.price} />}
        </div>
      </div>
    </div>
  );
};
_MenuIndexDish.propTypes = {
  dish: PropTypes.object,
  setCurrentItem: PropTypes.func.isRequired,
  stepIdx: PropTypes.number.isRequired,
  sectionIdx: PropTypes.number.isRequired,
  dishIdx: PropTypes.number.isRequired,
};

export const MenuIndexDish = connect(() => ({}), {})(_MenuIndexDish);

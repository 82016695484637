import { useApi, Request } from "@apparts/api";
import { isDevEnv } from "./envHelper";

const dev = isDevEnv();

let URL;
if (!dev && location.host === "dineup.app") {
  URL = "https://api.dineup.app/v/";
} else if (
  location.host !== "localhost:3000" ||
  location.host !== "localhost:3001"
) {
  URL = "https://1tcqiclktf.execute-api.eu-central-1.amazonaws.com/dev/v/";
} else {
  URL = "http://localhost:3000/v/";
}

class MyRequest extends Request {
  getURL(apiVersion) {
    // Tell Request what the URL prefix is
    return URL + apiVersion;
  }

  getAPIVersion() {
    // Tell Request what the default APIVersion is
    return 1;
  }

  online() {
    // Will be called, when a network-call succeded
  }

  async middleware() {
    this.on(0, () => {
      // on offline
    });
  }
}

const { get, put, patch, post, del } = useApi(MyRequest);
export { get, put, patch, post, del };

// TODO, midterm: needs to bubble up into a more global space:
//  -> config/database/api/deliver

const DuCurrencyIdentifierToSymbolMap = Object.freeze({
  EUR: "€",
});

export const getCurrencySymbolByIdentifier = (duCurrencyIdentifier) => {
  return DuCurrencyIdentifierToSymbolMap[duCurrencyIdentifier];
};

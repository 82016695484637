import { useIntl } from "react-intl";

export const usePaymentMethods = () => {
  const intl = useIntl();
  return [
    {
      identifier: "stripe",
      buttonText: intl.formatMessage({
        defaultMessage: "Mit Kreditkarte zahlen",
        description: "Pay with credit card button",
      }),
    },
    {
      identifier: "paypal",
      buttonText: intl.formatMessage({
        defaultMessage: "Mit PayPal bezahlen",
        description: "Pay with PayPal button",
      }),
    },
  ];
};

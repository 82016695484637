import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { post } from "util/api";
import { getCurrentTimestamp } from "util/index";

const getPaymentMethod = (paymentMethod) => {
  if (paymentMethod === "paypal") {
    const { protocol, host } = window.location;
    const returnUrl = `${protocol}//${host}/payment-return`;
    const cancelUrl = `${protocol}//${host}/payment-cancel`;

    return {
      processor: paymentMethod,
      returnUrl,
      cancelUrl,
    };
  } else {
    return { processor: paymentMethod };
  }
};

export const useSubmitOrder = ({ venue, order, storeOrderTracking }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [orderNote, setOrderNote] = useState("");
  return {
    orderNote,
    setOrderNote,
    loading,
    error,
    submitOrder: async (paymentMethod) => {
      const { id: venueId } = venue;
      setLoading(true);
      const orderRequestPayload = {
        uuid: uuidv4(),
        table: venue.table,
        order: order,
        menuVersion: venue.menuVersion,
        paymentMethod: getPaymentMethod(paymentMethod),
      };

      try {
        const response = await post("user/venue/$1/order", [venueId])
          .data(orderRequestPayload)
          .on(400, (error) => {
            setError(error.error);
          });

        storeOrderTracking({
          orderUuidClient: orderRequestPayload.uuid,
          orderIdRemote: response.id,
          orderNote,
          orderCreationTimestamp: getCurrentTimestamp(),
        });
        setLoading(false);
        return response;
      } catch (e) {
        setLoading(false);
        e && alert(e);
        return false;
      }
    },
  };
};

/*
   if (paymentMethodStr === "paypal") {
   redirectToPaymentApprovalLink(response);
   return null;
   } else if (paymentMethodStr === "stripe") {
   if (dontRedirect) {
   return response;
   } else {
   history.push("/credit-card");
   return null;
   }
   }
   return null;
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import stripePublicKey from "const/stripePublicKey";
import { DuActivityIndicator } from "components/index";

const Stripe = ({ children }) => {
  const [stripePromise, setStripePromise] = useState(null);
  useEffect(() => {
    setStripePromise(loadStripe(stripePublicKey));
  }, []);

  if (!stripePromise) {
    return <DuActivityIndicator />;
  } else {
    return <Elements stripe={stripePromise}>{children}</Elements>;
  }
};
Stripe.propTypes = { children: PropTypes.node };

export default Stripe;

import React from "react";
import { Link } from "react-router-dom";
import { DuButton } from "components";

export const DineupLanding = () => {
  return (
    <div
      style={{ minHeight: "100vh" }}
      className="flex flex-1 justify-center items-center flex-col gap-5
      bg-white px-4 "
    >
      <img src="dineup-logo-neg.svg" className="bg-primary-600" />
      <Link className="w-full" to="/qr">
        <DuButton wide label="Scan QR Code" />
      </Link>
      {URL}
      <div className="absolute bottom-0 flex m-5">
        <Link className="pr-2" to="/imprint">
          Imprint
        </Link>
        <Link className="pl-2" to="/legal">
          Legal
        </Link>
      </div>
    </div>
  );
};

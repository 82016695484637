import React from "react";
import PropTypes from "prop-types";

import { classNames } from "util/index";

export function DuActivityIndicator({ className, local, color }) {
  return (
    <div
      className={classNames(
        local
          ? "relative"
          : "fixed right-1/2 bottom-1/2 transform translate-x-1/2 translate-y-1/2 ",
        className
      )}
    >
      <span className="sr-only">Loading Spinner</span>
      <div
        style={{
          borderTopColor: "transparent",
          borderBottomColor: color,
          borderLeftColor: color,
          borderRightColor: color,
        }}
        className={classNames(
          "border-solid animate-spin rounded-full",
          !color && "border-white-400",
          local ? "border-2 h-8 w-8" : "border-8 h-64 w-64"
        )}
      />
    </div>
  );
}

DuActivityIndicator.propTypes = {
  className: PropTypes.string,
  local: PropTypes.bool,
  color: PropTypes.string,
};

const initState = false;

const reducer =
  (types) =>
  (defaultState = initState, action = {}) => {
    switch (action.type) {
      case types.SET_JUST_ADDED.name:
        return action.value;
      default:
        return defaultState;
    }
  };

export default reducer;

import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStripe as _useStripe } from "@stripe/react-stripe-js";

const useStripe = ({ venue, orderTotal, submitOrder }) => {
  const history = useHistory();
  const stripe = _useStripe();
  const [error, setError] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [buttonAvailabilityLoading, setButtonAvailabilityLoading] =
    useState(true);

  const onSuccess = () => {
    // The payment has succeeded.
    history.replace("/payment-return");
  };

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: venue.country,
        currency: venue.currency.toLowerCase(),
        total: {
          label: "Ihre Bestellung",
          amount: orderTotal,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((buttonsAvailable) => {
        if (buttonsAvailable) {
          setPaymentRequest(pr);

          pr.on("paymentmethod", async (ev) => {
            const response = await submitOrder("stripe");
            if (response === null) {
              return;
            }
            const {
              payment: { clientSecret },
            } = response;

            // Confirm the PaymentIntent without handling potential next actions (yet).
            const { paymentIntent, error: confirmError } =
              await stripe.confirmCardPayment(
                clientSecret,
                { payment_method: ev.paymentMethod.id },
                { handleActions: false }
              );

            if (confirmError) {
              setError("Card could not be confirmed");
              ev.complete("fail");
            } else {
              // Report to the browser that the confirmation was successful, prompting
              // it to close the browser payment method collection interface.
              ev.complete("success");
              // Check if the PaymentIntent requires any actions and if so let Stripe.js
              // handle the flow. If using an API version older than "2019-02-11"
              // instead check for: `paymentIntent.status === "requires_source_action"`.
              if (paymentIntent.status === "requires_action") {
                // Let Stripe.js handle the rest of the payment flow.
                const { error } = await stripe.confirmCardPayment(clientSecret);
                if (error) {
                  // The payment failed -- ask your customer for a new payment method.
                  setError("Walletpayment failed");
                } else {
                  onSuccess();
                }
              } else {
                onSuccess();
              }
            }
          });
        }
        setButtonAvailabilityLoading(false);
      });
    }
  }, [stripe]);
  return {
    walletButtons: paymentRequest,
    stripe,
    error,
    buttonAvailabilityLoading,
  };
};

export { useStripe };

import {
  isValidOrder,
  addOrderItemToOrder,
} from "@dineup/businesslogic/build/src";

const initState = { items: [] };
const reducer =
  (types) =>
  (state = initState, action = {}) => {
    switch (action.type) {
      case types.ADD_DISH.name: {
        const root = { menu: action.menu, order: state };
        const newOrder = addOrderItemToOrder(root, action.newDish);
        try {
          if (isValidOrder(newOrder, action.menu)) {
            return newOrder;
          }
        } catch (e) {
          console.log(e);
          alert(
            "Entschulgidung, es ist ein unerwarteter Fehler aufgetreten! Bitte versuchen Sie es nocheinmal."
          );
        }
        return state;
      }
      case types.SET_ORDER.name:
        return action.order;
      case types.RESET_ORDER.name:
        return initState;
      default:
        return state;
    }
  };

export default reducer;

import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { DuButton } from "components";
import { FormattedMessage } from "react-intl";
import { ErrorMessage } from "./PaymentError";

/**
 *
 * @param order
 * @param venue
 * @param setOrder
 * @param storeOrderTracking
 * @returns {JSX.Element}
 * @constructor
 */
function CreditCardForm({ submitOrder, theme, error }) {
  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  const elementStyle = {
    style: {
      base: {
        color: theme["--gray-800"],
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: theme["--gray-500"],
        },
        background: theme["--gray-50"],
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const [loading, setLoading] = useState(false);
  const [_clientSecret, setClientSecret] = useState(null);
  const handleSubmit = async (event) => {
    setLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    let clientSecret = _clientSecret;
    if (!_clientSecret) {
      const response = await submitOrder("stripe");
      if (!response) {
        setLoading(false);
        return;
      }
      setClientSecret(response.payment.clientSecret);
      clientSecret = response.payment.clientSecret;
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      alert(result.error.message);
      setLoading(false);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        history.replace("/payment-return");
      }
    }
  };

  if (!stripe) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="mt-4 relative rounded-md ">
          <label>
            <FormattedMessage
              defaultMessage="Kreditkarte"
              description="Label of creditcard input field"
            />
          </label>
          <div
            className={
              "p-4 pb-3 my-2" +
              " shadow-sm focus:ring-primary-500 focus:border-primary-500" +
              " block w-full sm:text-sm border border-gray-300 rounded-md"
            }
          >
            <CardElement options={elementStyle} />
          </div>
        </div>
        <div className="flex justify-end">
          <DuButton type="submit" disabled={!stripe} loading={loading}>
            <FormattedMessage
              defaultMessage="Kauf abschließen"
              description="Buy with credit card button"
            />
          </DuButton>
        </div>
        {error && (
          <div className="text-red-600 pt-2">
            <ErrorMessage error={error} />
          </div>
        )}{" "}
      </form>
    </div>
  );
}

CreditCardForm.propTypes = {
  submitOrder: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  error: PropTypes.string,
};

export default CreditCardForm;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { DuCounter, DuButton, DuTextField, DuCurrencyFormat } from "components";
import { SubSection } from "./SubSection";
import { XIcon } from "@heroicons/react/outline";
import { MenuDishOverlay } from "./MenuDishOverlay";
import {
  isValidOrderItem,
  getOrderItemTotal,
  createValidOrderSubsectionsFromMenuSection,
  getTupleSubsections,
  pruneOrderItem,
  replaceOrderSubsection,
  getMenuSteps,
  getMenuSections,
  getMenuItems,
} from "@dineup/businesslogic/build/src";
import { classNames } from "util/index";

import { addDishToBasket } from "redux/orderStore/actions";

function _MenuDishDetails({ venue = {}, addDishToBasket }) {
  const history = useHistory();
  const {
    stepIndex: str_stepIndex,
    sectionIndex: str_sectionIndex,
    itemIndex: str_itemIndex,
  } = useParams();
  const stepIndex = parseInt(str_stepIndex);
  const sectionIndex = parseInt(str_sectionIndex);
  const itemIndex = parseInt(str_itemIndex);

  const { menu } = venue;

  const dish = menu
    ? getMenuItems(
        getMenuSections(getMenuSteps(menu)[stepIndex] || {})[sectionIndex] || {}
      )[itemIndex] || {}
    : {};

  const [open, setOpen] = useState(true);
  const onClose = () => {
    setOpen(false);
    history.goBack();
  };

  const [order, setOrder] = useState({
    amount: 1,
    sections: createValidOrderSubsectionsFromMenuSection(dish.sections),
  });

  const checkOrder = (oldOrder, newOrder) => {
    try {
      if (isValidOrderItem({ order: newOrder, menu: dish })) {
        return newOrder;
      } else {
        return oldOrder;
      }
    } catch (e) {
      console.log(e);
      return oldOrder;
    }
  };

  const onNewAmount = (requestedAmount) => {
    setOrder((order) => {
      const newOrder = { ...order, amount: requestedAmount };
      return checkOrder(order, newOrder);
    });
  };

  const onChangeSubSection = (index, newSubSection) => {
    setOrder((order) => {
      console.log({ menu: dish, order });
      const newOrder = pruneOrderItem(
        replaceOrderSubsection(
          index,
          { menu: dish, order },
          () => newSubSection
        )
      );
      return checkOrder(order, newOrder);
    });
  };

  const addToCart = () => {
    addDishToBasket({
      ...order,
      stepIndex: stepIndex,
      sectionIndex: sectionIndex,
      itemIndex: itemIndex,
    });
    history.goBack();
  };

  const subSectionTuples = getTupleSubsections({ menu: dish, order });

  const price = getOrderItemTotal({ order, menu: dish });

  if (!menu) {
    history.push("/order");
    return <div />;
  }

  return (
    <MenuDishOverlay setOpen={setOpen} onClose={onClose} open={open}>
      <div
        className={classNames(
          "relative flex-1 bg-gray-100",
          dish.image ? "mt-48" : ""
        )}
      >
        <div className="flex flex-col">
          {dish.image && (
            <div
              style={{ zIndex: -1 }}
              className="fixed flex top-0 h-48 bg-white"
            >
              <img src={dish.image} className="object-center object-cover" />
            </div>
          )}
          <div
            className={classNames(
              "p-4 pt-6 -mt-6 -mb-4 bg-gray-100",
              "flex items-center justify-between",
              dish.image && "rounded-lg"
            )}
          >
            <h1 className="text-gray-900 font-bold text-xl">{dish.name}</h1>
            <button
              className=" rounded-md text-gray-400 hover:text-gray-500
    self-start
bg-gray-100 border-none focus:ring-offset-gray-100
 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              onClick={onClose}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="p-4 pb-2">
            <p className="text-gray-600 text-sm">{dish.description}</p>
          </div>
        </div>
        <div className="p-4 pt-2 pb-20">
          {subSectionTuples.map((subsectionTuple, i) => (
            <SubSection
              onChange={(newItemTuples) => onChangeSubSection(i, newItemTuples)}
              key={i}
              subSectionTuple={subsectionTuple}
            />
          ))}
          <DuTextField
            label={<h2>Haben Sie Anmerkungen zu Ihrer Bestellung?</h2>}
          />
          <div
            className=" w-full
 fixed z-20 bottom-0 left-0 pt-0
 items-center bg-white shadow-lg p-5"
            style={{
              boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.3)",
            }}
          >
            <div className="-mt-4  flex flex-row rounded-md">
              <div
                className="rounded-md w-1/3 mr-4"
                style={{
                  boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.2)",
                }}
              >
                <DuCounter
                  tiny
                  flexWidth
                  high
                  value={order.amount}
                  onChangeRequest={onNewAmount}
                />
              </div>
              <div
                className="flex-grow rounded-md"
                style={{
                  boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.3)",
                }}
              >
                <DuButton wide onClick={addToCart}>
                  Hinzufügen <span className="w-2"> </span>
                  <DuCurrencyFormat value={price} />
                </DuButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MenuDishOverlay>
  );
}

_MenuDishDetails.propTypes = {
  venue: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  addDishToBasket: PropTypes.func.isRequired,
};

export const MenuDishDetails = connect(({ venue }) => ({ venue }), {
  addDishToBasket,
})(_MenuDishDetails);

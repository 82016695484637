export const setOffline = () => ({
  type: "SET_NETWORK",
  state: false,
});

export const setOnline = () => ({
  type: "SET_NETWORK",
  state: true,
});

export default [setOffline().type];

import React from "react";
import PropTypes from "prop-types";
import {
  DuCounter,
  DuBackButton,
  DuTextField,
  DuCurrencyFormat,
} from "components";

const BasketView = ({
  dishes,
  requestAmountChange,
  isOrderEmpty,
  orderNote,
  setOrderNote,
  children,
  orderTotal,
}) => {
  if (isOrderEmpty) {
    return (
      <div className="pt-4">
        <div className="flex flex-col justify-center w-full bg-white p-4 mt-4">
          <div className="flex justify-center text-gray-900 font-bold text-l">
            Ihr Warenkorb ist leer.
          </div>
          <div className="flex flex-col justify-center mt-2 text-gray-600 text-sm">
            <div className="flex justify-center">
              Fügen Sie ihrer Bestellung Artikel hinzu.
            </div>
            <div className="flex justify-center">
              <DuBackButton className="m-5 self-start">
                Zurück zum Menü
              </DuBackButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-4">
      <div className="bg-white overflow-hidden sm:rounded-md">
        <ul className="">
          {dishes.map(({ key, ...dish }) => (
            <li key={key}>
              <div className="block hover:bg-gray-50 border-b border-gray-200">
                <div className="px-4 py-2 sm:px-6">
                  <div className="flex items-center justify-between">
                    <h1 className="text-gray-900 font-bold text-l">
                      {dish.menu.name}
                    </h1>
                    <div className="ml-2 flex-shrink-0 flex">
                      <DuCounter
                        tiny
                        value={dish.order.amount}
                        onChangeRequest={(newAmount) =>
                          requestAmountChange(dish, newAmount)
                        }
                      />
                    </div>
                  </div>
                  <div className="mt-2 flex justify-end ">
                    <DuCurrencyFormat value={dish.total} />
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* total sum section */}
      <div className="p-4 w-full flex justify-end">
        <b className="pr-2">Summe:</b>
        <DuCurrencyFormat value={orderTotal} />
      </div>

      <div className="p-4">
        <DuTextField
          label="Haben Sie Anmerkungen zu Ihrer Bestellung?"
          onTextChange={setOrderNote}
          value={orderNote}
        />

        {children}
      </div>
    </div>
  );
};

BasketView.propTypes = {
  orderTotal: PropTypes.number.isRequired,
  dishes: PropTypes.array.isRequired,
  requestAmountChange: PropTypes.func.isRequired,
  setOrderNote: PropTypes.func.isRequired,
  orderNote: PropTypes.string.isRequired,
  isOrderEmpty: PropTypes.bool,
  children: PropTypes.node,
};

export default BasketView;

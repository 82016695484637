import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  DuButton,
  OrderStatusProgressVertical,
  DuCurrencyFormat,
} from "components";
import SendReceipt from "./SendReceipt";
import { resetOrder } from "redux/orderStore/actions";
import { get } from "util/api";
import { FETCH_ORDER_STATUS_POLLING_INTERVAL_MSEC } from "./orderConfirmationConfig";

import {
  getOrderItemTotal,
  getTupleItemsPruned,
} from "@dineup/businesslogic/build/src";

const stateToDisplayState = (state, venue) => {
  switch (state.state) {
    case "payment intent created":
      return {
        stateTitleText: "Zahlung abgeschlossen",
      };
    case "payment aborted":
      return {
        stateTitleText: "Zahlung fehlgeschlagen",
        stateDescription:
          "Leider konnten wir Ihre Zahlung nicht verarbeiten. Verwenden Sie bitte bei einer erneuten Bestellung ein anderes Zahlungsmittel.",
      };
    case "order in process":
      return {
        stateTitleText: "Bestellung in Arbeit",
        stateDescription: `Aktuelle Wartezeit beträgt ca. ${venue.waitTime} Minuten`,
      };
    case "order done":
      return {
        stateTitleText: "Bestellung abholbereit",
      };
    default:
      return null;
  }
};

const successStates = ({ allowedOrderStates }) => {
  return allowedOrderStates.filter(({ guest, success }) => guest && success);
};

const isSuccessResponse = (response, { allowedOrderStates }) => {
  return (
    response.filter(
      ({ state }) =>
        !(allowedOrderStates.filter(({ name }) => state === name)[0] || {})
          .success
    ).length === 0
  );
};

/**
 *
 * @param venue
 * @param order
 * @param orderTracking
 * @param resetOrder
 * @returns {JSX.Element}
 * @constructor
 */
const _OrderConfirmation = ({ venue, order, orderTracking, resetOrder }) => {
  const { orderUuidClient: orderUuid } = orderTracking;
  const history = useHistory();
  const [status, setStatus] = useState(
    successStates(venue).map(({ name }) => ({ state: name }))
  );
  const steps = status.map((state, i) => stateToDisplayState(state, venue, i));
  const [currentState, setCurrentState] = useState(1);

  const loadStatus = async (venue, orderTracking) => {
    const { orderIdRemote, orderUuidClient: orderUuid } = orderTracking;
    if (orderUuid) {
      try {
        const response = await get("user/venue/$1/order/$2/status", [
          venue.id,
          orderIdRemote,
        ]).query({ uuid: orderUuid });
        if (!isSuccessResponse(response, venue)) {
          setStatus(
            response.filter(
              ({ state }) =>
                (
                  venue.allowedOrderStates.filter(
                    ({ state: state1 }) => state === state1
                  )[0] || {}
                ).guest
            )
          );
          setCurrentState(response.length - 1);
        } else {
          const currentResponse = response.reverse()[0] || {
            name: "payment done",
          };
          const current = successStates(venue)
            .map(({ name }) => name)
            .indexOf(currentResponse.state);
          setCurrentState(current === -1 ? 1 : current + 1);
        }
      } catch (e) {
        e && alert(e);
      }
    }
  };

  useEffect(() => {
    loadStatus(venue, orderTracking);
    const intervalHandle = setInterval(
      () => loadStatus(venue, orderTracking),
      FETCH_ORDER_STATUS_POLLING_INTERVAL_MSEC
    );

    return () => clearInterval(intervalHandle);
  }, [venue, order, orderTracking]);

  const placeNewOrder = () => {
    resetOrder();
    history.push("/venue-welcome");
  };

  if (!venue || !order || !orderUuid || !venue.menu) {
    history.push("/");
    return <div />;
  }
  const { menu } = venue;

  return (
    <div className="flex-1 flex flex-col justify-center w-full bg-gray-100 p-5">
      <div
        className="bg-white overflow-hidden shadow rounded-lg p-4
    py-6 my-4"
      >
        <OrderStatusProgressVertical
          progressStates={steps}
          currentStateIdentifier={currentState}
        />
      </div>
      <SendReceipt />

      <div className="flex-1 flex flex-col justify-center w-full bg-gray-100 pb-4">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
            Ihre Bestellung im Überblick
          </div>
          <div className="px-4 py-5">
            <ul className="">
              {getTupleItemsPruned({ menu, order }).map(
                (dish, dishOrderIndex) => (
                  <li
                    key={`${dish.stepIndex} ${dish.sectionIndex} ${dish.dishIndex} ${dishOrderIndex}`}
                  >
                    <div className="block">
                      <div className="px-4 py-2 sm:px-6">
                        <div className="flex items-center justify-between">
                          <p className="text-gray-900 ">
                            {dish.order.amount}x {dish.menu.name}
                          </p>
                          <DuCurrencyFormat value={getOrderItemTotal(dish)} />
                        </div>
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <DuButton
          onClick={() => placeNewOrder()}
          label="Weitere Bestellung aufgeben"
        />
      </div>
    </div>
  );
};

_OrderConfirmation.propTypes = {
  venue: PropTypes.object,
  order: PropTypes.object,
  orderTracking: PropTypes.object,
  resetOrder: PropTypes.func.isRequired,
};

export const OrderConfirmation = connect(
  ({ venue, order, orderTracking }) => {
    return {
      venue,
      order,
      orderTracking,
    };
  },
  {
    resetOrder,
  }
)(_OrderConfirmation);

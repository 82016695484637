import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import { ChevronDoubleRightIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/outline";

import {
  getMenuSteps,
  getTupleItemsPruned,
} from "@dineup/businesslogic/build/src/";

import { MenuStep } from "views";
import { MenuStepNav } from "./MenuStepNav";
import { DuButton, DuCartBadge, DuToast } from "components";

import { setJustAdded } from "redux/justAdded/actions";

const _MenuShell = ({ venue, order, justAdded, setJustAdded }) => {
  const { id: menuStepIdxStr } = useParams();
  let menuStepIdx;
  try {
    menuStepIdx = parseInt(menuStepIdxStr);
  } catch {
    menuStepIdx = 0;
  }

  const history = useHistory();

  const setMenuStepIdx = (id) => {
    history.push("/order/" + id);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [menuStepIdx]);

  if (!venue || !venue.menu) {
    history.push("/");
    return <div />;
  }

  const { menu } = venue;
  const dishCount = getTupleItemsPruned({ order, menu }).reduce(
    (a, { order: { amount } }) => a + amount,
    0
  );
  const menuSteps = getMenuSteps(venue.menu);

  const [showToast, setShowToast] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    if (justAdded) {
      if (showToast) {
        clearTimeout(showToast);
      }
      setShowToast(setTimeout(() => setShowToast(false), 3000));
      history.replace(pathname);
      setJustAdded(false);
    }
  }, [order]);

  return (
    <div className="MenuShell">
      <MenuStepNav
        menuSteps={menuSteps}
        onSelection={(i) => setMenuStepIdx(i)}
        currentStep={menuStepIdx}
      />
      <MenuStep stepIdx={menuStepIdx} menuStep={menuSteps[menuStepIdx]} />
      {/*getränke navigation*/}
      {menuSteps.length - 1 !== menuStepIdx && (
        <div
          onClick={() => setMenuStepIdx(menuSteps.length - 1)}
          className="flex flex-row justify-end items-center bg-primary-600 overflow-hidden shadow-lg rounded-lg m-4 p-4 text-white"
        >
          <div className="px-4 py-5 sm:p-6">Zur Getränke Auswahl</div>
          <ChevronDoubleRightIcon className="mx-5 h-5 w-5" aria-hidden="true" />
        </div>
      )}
      <div className="h-16"></div>
      {/*shopping cart icon*/}
      <div
        className="fixed bottom-0 w-full p-5 z-30 bg-white pt-0"
        style={{
          boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.3)",
        }}
      >
        <Link
          to="/basket"
          className="block -mt-4 rounded-md"
          style={{
            boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.3)",
          }}
        >
          <DuButton wide>
            <div className="flex flex-row w-full justify-between">
              <ShoppingCartIcon
                className="-ml-1 mr-3 h-5 w-5"
                aria-hidden="true"
              />
              <div className="flex flex-row items-center">Ihre Bestellung</div>
              <div className="flex-grow flex justify-end">
                <DuCartBadge badgeCount={dishCount} />
              </div>
            </div>
          </DuButton>
        </Link>
      </div>
      <DuToast
        show={!!showToast}
        setShow={setShowToast}
        title={"Zum Warenkorb hinzugefügt"}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-primary-400"
            aria-hidden="true"
          />
        }
      />
    </div>
  );
};

_MenuShell.propTypes = {
  venue: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  justAdded: PropTypes.bool,
  setJustAdded: PropTypes.func.isRequired,
};

const MenuShell = connect(
  ({ venue, order, justAdded }) => ({
    venue,
    order,
    justAdded,
  }),
  { setJustAdded }
)(_MenuShell);

export { MenuShell };

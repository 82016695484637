const initState = {};

const reducer =
  (types) =>
  (defaultState = initState, action = {}) => {
    switch (action.type) {
      case types.SET_NETWORK.name:
        return defaultState;
      default:
        return defaultState;
    }
  };

export default reducer;

import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { PhoneIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { DuButton } from "components";
import { DuFormikInput } from "components";
import isPhoneValid from "util/isPhoneValid";
import { put } from "util/api";

_OrderStatusNotification.propTypes = {
  order: PropTypes.object.isRequired,
  venue: PropTypes.object.isRequired,
  orderTracking: PropTypes.object.isRequired,
};

function _OrderStatusNotification({ order, venue, orderTracking = {} }) {
  const { orderUuidClient: orderUuid } = orderTracking;
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const submitContactInfo = async ({ phone }) => {
    try {
      setLoading(true);
      await put("user/venue/$1/order/$2/contact", [venue.id, order.id])
        .data({ phone, uuid: orderUuid })
        .on({ status: 400, error: "phone not well defined" }, () => {
          alert(
            "Bitte überprüfen Sie die eingegebene Telefonnummer, sie scheint ungültig zu sein."
          );
        });
      setLoading(false);
      history.push("/confirmation");
    } catch (e) {
      e && alert(e);
      setLoading(false);
    }
  };

  if (!venue || !order || !orderUuid || !venue.menu) {
    history.push("/");
    return <div />;
  }

  return (
    <div className="flex-1 flex flex-col justify-center w-full bg-gray-100 p-5">
      <Formik
        initialValues={{ phone: "" }}
        validationSchema={Yup.object({
          phone: Yup.string()
            .matches(
              isPhoneValid(),
              "Bitte geben Sie eine gültige Telefonnummer ein."
            )
            .required("Bitte geben Sie eine Telefonnummer an."),
        })}
        onSubmit={submitContactInfo}
      >
        <Form>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
              <p>
                Bitte geben Sie Ihre Telefonnummer an, damit wir Ihnen eine SMS
                schicken können, sobald Ihre Bestellung abholbereit ist.
              </p>
            </div>
            <div className="px-4 py-5">
              <DuFormikInput
                placeholder="Telefonnummer"
                Icon={PhoneIcon}
                name="phone"
              />
            </div>
            <div className="px-4 pb-5 flex justify-between">
              <Link to="/confirmation">
                <DuButton secondary>Skip</DuButton>
              </Link>
              <DuButton loading={loading} type={"submit"}>
                Abschicken
              </DuButton>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export const AddContactInfo = connect(({ order, venue, orderTracking }) => ({
  order,
  venue,
  orderTracking,
}))(_OrderStatusNotification);

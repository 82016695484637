import React from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { getCurrencySymbolByIdentifier } from "const/currencySymbolMap";

/**
 *
 * @param value
 * @param currencyString
 * @param plus: boolean, prefix with plus-sign
 * @param separator
 * @returns {JSX.Element}
 * @private
 */
export const _DuCurrencyFormat = ({
  value,
  currencyString,
  plus,
  separator = ",",
}) => {
  const getMoneyAsString = (price) => {
    const euro = Math.floor(price / 100);
    const cent = price % 100;
    return `${euro}${separator}${"0".repeat(2 - (cent + "").length)}${cent}`;
  };

  const isPlusPrefixVisible = plus && value >= 0;

  return (
    <span className="whitespace-nowrap">
      {isPlusPrefixVisible ? "+ " : ""}
      {getMoneyAsString(value)} {currencyString}
    </span>
  );
};

_DuCurrencyFormat.propTypes = {
  value: PropTypes.number.isRequired,
  currencyString: PropTypes.string.isRequired,
  plus: PropTypes.bool,
  separator: PropTypes.string,
};

export const DuCurrencyFormat = connect(({ venue: { currency } }) => {
  const duCurrencyIdentifier = currency;
  const currencySymbol = getCurrencySymbolByIdentifier(duCurrencyIdentifier);
  return {
    currencyString: currencySymbol,
  };
})(_DuCurrencyFormat);

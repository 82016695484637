import { languages } from "../lang";

const getLocale = () => window.navigator.languages[0];

const getTranslations = (locale, defaultLocale) => {
  const [language] = locale.split("-");

  return (
    languages[locale] || languages[language] || languages[defaultLocale] || {}
  );
};

export { getLocale, getTranslations };

import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

const ORDER_REDIRECT_TIMEOUT = 1000 * 60 * 60 * 1;

const useRedirectOnActiveOrder = (venue, order) => {
  const history = useHistory();
  useEffect(() => {
    if (
      venue &&
      order &&
      order.id &&
      order.uuid &&
      order.created > Date.now() - ORDER_REDIRECT_TIMEOUT
    ) {
      history.push("/confirmation");
    }
  }, [venue, order]);
};

const useSearch = () => {
  const { search } = window.location; //useLocation();
  if (search) {
    const res = {};
    search
      .slice(1)
      .split("&")
      .forEach((part) => {
        const [key] = part.split("=", 1);
        res[key] = part.slice(key.length + 1);
        try {
          res[key] = JSON.parse(decodeURIComponent(res[key]));
        } catch (e) {
          console.log("could not parse search params");
        }
      });
    return res;
  }
  return {};
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export { useRedirectOnActiveOrder, useSearch, usePrevious };

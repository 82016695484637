import React from "react";
import PropTypes from "prop-types";
import { Switch } from "@headlessui/react";

import { classNames } from "util/index";

DuToggle.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

export function DuToggle({ checked, onClick }) {
  return (
    <Switch
      checked={checked}
      onChange={onClick}
      className={classNames(
        checked ? "bg-primary-600" : "bg-gray-200",
        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent " +
          "rounded-full cursor-pointer transition-colors ease-in-out " +
          "duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 " +
          "focus:ring-primary-500"
      )}
    >
      <span className="sr-only">toggle</span>
      <span
        aria-hidden="true"
        className={classNames(
          checked ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow " +
            "transform ring-0 transition ease-in-out duration-200"
        )}
      />
    </Switch>
  );
}

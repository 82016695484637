import "./_VenueWelcome.sass";

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { DuButton, DuInfoWell } from "components";

const _VenueWelcome = ({
  venue: { waitTime, logo, heroImage, featureConfig: { welcomeWaittime } = {} },
}) => {
  return (
    <div
      className="flex-1 flex flex-col items-center justify-center
  bg-gray-100 bg-center bg-cover"
      style={{ backgroundImage: `url("${heroImage}")`, minHeight: "100vh" }}
    >
      <div className="flex flex-col items-center p-5 gap-4">
        <div className="w-2/3 pb-12 -mt-12" style={{ height: "35vw" }}>
          {logo && <img className="object-contain" src={logo} />}
        </div>

        <Link to="/order" className="">
          <DuButton className="px-8 text-lg">
            <FormattedMessage
              description="Open menu button"
              defaultMessage="Speisekarte öffnen"
            />
          </DuButton>
        </Link>
      </div>

      {welcomeWaittime && (
        <DuInfoWell className="fixed bottom-14 left-4 right-4 overflow-hidden ">
          {waitTime && (
            <span className="px-6">
              <FormattedMessage
                defaultMessage="Aktuelle Wartezeit: {waitTime, plural, one {# Minute} other {# Minuten}}"
                description="Expected waiting time on welcome screen"
                values={{ waitTime }}
              />
            </span>
          )}
        </DuInfoWell>
      )}

      <div className="absolute bottom-0 flex m-5">
        <Link className="pr-2" to="/imprint">
          Imprint
        </Link>
        <Link className="pl-2" to="/legal">
          Legal
        </Link>
      </div>
    </div>
  );
};

_VenueWelcome.propTypes = {
  venue: PropTypes.object.isRequired,
};

const mapStateToProps = ({ venue }) => ({
  venue,
});

const dispatchToPropsMap = {};

export const VenueWelcome = connect(
  mapStateToProps,
  dispatchToPropsMap
)(_VenueWelcome);

// Europe only, currently
const cCodes = [
  "7", //  Abchasien
  "355", //  Albanien
  "376", //  Andorra
  "375", //  Belarus
  "32", //  Belgien
  "387", //  Bosnien und Herzegowina
  "359", //  Bulgarien
  "45", //  Dänemark
  "49", //  Deutschland
  "372", //  Estland
  "298", //  Färöer
  "358", //  Finnland
  "33", //  Frankreich
  "350", //  Gibraltar
  "30", //  Griechenland
  "44", //  Großbritannien
  "44", //  Isle of Man
  "44", //  Kanalinseln
  "353", //  Irland
  "354", //  Island
  "39", //  Italien
  "383", //  Kosovo
  "385", //  Kroatien
  "371", //  Lettland
  "423", //  Liechtenstein
  "370", //  Litauen
  "352", //  Luxemburg
  "356", //  Malta
  "389", //  Mazedonien
  "373", //  Moldawien
  "377", //  Monaco
  "382", //  Montenegro
  "31", //  Niederlande
  "47", //  Norwegen
  "43", //  Österreich
  "48", //  Polen
  "351", // Portugal
  "40", //  Rumänien
  "378", //  San Marino
  "46", //  Schweden
  "41", //  Schweiz
  "381", //  Serbien
  "421", //  Slowakei
  "386", //  Slowenien
  "34", //  Spanien
  "7", //  Südossetien
  "420", //  Tschechien
  "90", //  Türkische Republik Nordzypern
  "380", //  Ukraine
  "36", //  Ungarn
  "379", //  Vatikanstadt
  "44", //  Vereinigtes Königreich
  "375",
]; //  Weißrussland

export default () =>
  new RegExp(
    `^((\\+(${cCodes.join(
      "|"
    )}))|0)(\\(?([\\d \\-\\)\\–\\+\\/\\(]+)\\)?([ .\\-–\\/]?)([\\d]+))$`
  );

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";

/**
 * Stashes further details away behind a question mark button
 *
 * @param info
 * @returns {JSX.Element|null}
 * @constructor
 */
const DuInfo = ({ info }) => {
  if (!info) {
    return null;
  }
  return (
    <span className="h-5 w-5 ml-2">
      <Menu as="div" className="relative">
        {({ open }) => (
          <>
            <div>
              <Menu.Button
                className="focus:ring-2 focus:ring-offset-2
              focus:ring-primary-500 outline-none rounded-full"
              >
                <QuestionMarkCircleIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="sr-only">Zeige zusätzliche Informationen</span>
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="origin-top-right absolute right-0 z-50
 mt-2 w-48
rounded-md shadow-lg py-1 bg-white
 ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <p className="px-4 py-2">{info}</p>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </span>
  );
};

DuInfo.propTypes = {
  info: PropTypes.node,
};

export { DuInfo };

import React from "react";
import PropTypes from "prop-types";

import { classNames } from "util/index";

export function DuCounter({
  min = 0,
  max = Infinity,
  onChangeRequest,
  value = "",
  tiny,
  flexWidth,
  high,
}) {
  return (
    <div
      className={classNames(
        flexWidth ? "" : tiny ? "w-20" : "w-32",
        high ? "h-12" : tiny ? "h-8" : "h-10"
      )}
    >
      <div
        className="flex flex-row w-full rounded-md relative bg-transparent
  h-full bg-gray-200"
      >
        <button
          data-action="decrement"
          className=" text-gray-800 hover:text-gray-700
focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
          onClick={() => onChangeRequest(Math.max(min, value - 1))}
        >
          <span className="m-auto text-2xl font-thin">-</span>
        </button>

        <div
          className="flex justify-center outline-none
 focus:outline-none text-center w-full 
 font-semibold text-md hover:text-black focus:text-black
 md:text-basecursor-default flex items-center text-gray-700
outline-none "
        >
          {value}
        </div>
        <button
          data-action="increment"
          className="flex justify-center content-center 
focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
 text-gray-800 hover:text-gray-700 hover:bg-gray-400
 h-full w-20 rounded-r cursor-pointer"
          onClick={() => onChangeRequest(Math.min(max, value + 1))}
        >
          <span className="m-auto text-2xl font-thin">+</span>
        </button>
      </div>
    </div>
  );
}

DuCounter.propTypes = {
  value: PropTypes.number,
  onChangeRequest: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  tiny: PropTypes.bool,
  flexWidth: PropTypes.bool,
  high: PropTypes.bool,
};

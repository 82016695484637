import React from "react";
import PropTypes from "prop-types";

export function DuCartBadge({ badgeCount }) {
  return (
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-pink-100 text-primary-800">
      {badgeCount}
    </span>
  );
}

DuCartBadge.propTypes = {
  badgeCount: PropTypes.number,
};

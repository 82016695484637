import React, { useEffect } from "react";
import { connect, Provider } from "react-redux";
import store, { persistor } from "redux/store";
import PropTypes from "prop-types";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";

import { AppContentPane } from "view-shells";
import { getLocale, getTranslations } from "util/i18n";

const locale = getLocale();
const translations = getTranslations(locale);

const ScrollToTop = withRouter(({ children, location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
});

const _Themed = ({ venue, children }) => {
  useEffect(() => {
    setTheme();
  }, [venue]);

  const setTheme = () => {
    if (venue && venue.colors) {
      const rootDoc = document.querySelector(":root");

      for (const colorKey in venue.colors) {
        rootDoc.style.setProperty(colorKey, venue.colors[colorKey]);
      }
    }
  };
  return children;
};
_Themed.propTypes = { venue: PropTypes.object, children: PropTypes.node };
const Themed = connect(({ venue }) => ({ venue }), {})(_Themed);

const App = () => (
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Router>
          <ScrollToTop>
            <Themed>
              <IntlProvider locale={locale} messages={translations}>
                <AppContentPane />
              </IntlProvider>
            </Themed>
          </ScrollToTop>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

export { App };

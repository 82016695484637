import { setJustAdded } from "../justAdded/actions";
import {
  isValidOrderItem,
  getMenuSteps,
  getMenuSections,
  getMenuItems,
} from "@dineup/businesslogic/build/src/";

export const setOrder = (order) => ({
  type: "SET_ORDER",
  order,
});

export const resetOrder = () => ({
  type: "RESET_ORDER",
});

export const addDish = (menu, newDish) => ({
  type: "ADD_DISH",
  menu,
  newDish,
});

export const addDishToBasket = (newDish) => (dispatch, getState) => {
  const {
    venue: { menu },
  } = getState();
  const { stepIndex, sectionIndex, itemIndex } = newDish;

  const menuItem = getMenuItems(
    getMenuSections(getMenuSteps(menu)[stepIndex])[sectionIndex]
  )[itemIndex];

  try {
    isValidOrderItem({ order: newDish, menu: menuItem });
  } catch (e) {
    console.log(e);
    alert(
      "Entschulgidung, es ist ein unerwarteter Fehler aufgetreten! Bitte versuchen Sie es nocheinmal."
    );
    return;
  }

  dispatch(addDish(menu, newDish));
  dispatch(setJustAdded(true));
};

export default [setOrder().type, resetOrder().type, addDish().type];

const initState = {
  orderUuidClient: null,
  orderIdRemote: null,
  orderNote: null,
  orderCreationTimestamp: null,
};

const reducer =
  (types) =>
  (state = initState, action = {}) => {
    switch (action.type) {
      case types.STORE_ORDER_TRACKING.name:
        return action.orderTracking;

      default:
        return state;
    }
  };

export default reducer;

import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { DuActivityIndicator } from "components";
import { isAddContactInfoScreenEnabled } from "accessors/venueUtils";

const _PaymentReturn = ({ venue }) => {
  const history = useHistory();
  const redirectPath = isAddContactInfoScreenEnabled(venue)
    ? "/contact"
    : "/confirmation";
  history.push(redirectPath);

  return (
    <div>
      <DuActivityIndicator />
    </div>
  );
};

_PaymentReturn.propTypes = {
  venue: PropTypes.object.isRequired,
};

export const PaymentReturn = connect(({ venue }) => ({ venue }))(
  _PaymentReturn
);

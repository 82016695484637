import React from "react";
import PropTypes from "prop-types";

import { MenuIndexDish } from "./MenuIndexDish";
import { getMenuItems } from "@dineup/businesslogic/build/src/";

const MenuIndexSection = ({ menuSection, stepIdx, sectionIdx }) => {
  const dishes = getMenuItems(menuSection);

  return (
    <div className="relative">
      {/*sub-category sticky header*/}
      <div
        className="
 border-t border-b border-gray-200
 bg-primary-600
 top-32
text-center text-sm font-medium text-white
 bg-center bg-cover"
        style={{
          backgroundImage: menuSection.image
            ? `url("${menuSection.image}")`
            : "",
        }}
      >
        <div className="bg-opacity-30 bg-black p-6 py-8">
          <h3
            className="text-2xl font-bold"
            style={{ textShadow: "0px 0px 8px black" }}
          >
            {menuSection.name}
          </h3>
          {menuSection.description && (
            <div
              className="text-lg"
              style={{ textShadow: "0px 0px 8px black" }}
            >
              {menuSection.description}
            </div>
          )}
        </div>
      </div>

      {dishes.map((dish, i) => (
        <MenuIndexDish
          stepIdx={stepIdx}
          sectionIdx={sectionIdx}
          dishIdx={i}
          key={i}
          dish={dish}
        />
      ))}
    </div>
  );
};

MenuIndexSection.propTypes = {
  menuSection: PropTypes.object,
  stepIdx: PropTypes.number.isRequired,
  sectionIdx: PropTypes.number.isRequired,
};

export { MenuIndexSection };

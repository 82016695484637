import { isProdEnv } from "util/envHelper";

let key =
  "pk_test_51J57pDFkjU0fthGjpU26NOr9VxXsrLx3qllF0bi9m6iaZywYl7PCCIKUGc5Owml7weliLRcwo7jafQdcgvng8Vb100pzm9pl8I";

if (isProdEnv() && location.host !== "dev.dineup.app") {
  key =
    "pk_live_51J57pDFkjU0fthGjAUDvaVfe9Jthu0PTnDlTeVlcuVKXTOSgu5Bgx9eyHarncfoVfux2y6BuYIoXQ1Q7g30mR7dT00h04ONN6j";
}

export default key;

import React from "react";
import { Provider } from "react-redux";
import configureStore from "@apparts/redux";

import defaultStore from "./defaultStore";
import venueStore from "./venueStore";
import orderStore from "./orderStore";
import orderTrackingStore from "./orderTrackingStore";
import justAdded from "./justAdded";

const { store, persistor } = configureStore({
  defaultStore,
  venue: venueStore,
  order: orderStore,
  orderTracking: orderTrackingStore,
  justAdded,
});

const withStore = (app) => <Provider store={store}>{app}</Provider>;
export { persistor, withStore };
export default store;

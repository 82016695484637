import React from "react";
import PropTypes from "prop-types";
import { RadioGroup } from "@headlessui/react";

import { DuCurrencyFormat, DuInfo } from "components";
import { classNames } from "util/index";

function OneOfItem({ itemTuple, isFirst, isLast, index }) {
  const { menu } = itemTuple;

  return (
    <RadioGroup.Option
      value={index}
      className={({ checked }) =>
        classNames(
          isFirst ? "rounded-tl-md rounded-tr-md" : "",
          isLast ? "rounded-bl-md rounded-br-md" : "",
          checked ? "bg-primary-50 border-primary-200 z-10" : "border-gray-200",
          "relative border p-4 text-sm",
          "flex items-center",
          "md:pl-4 md:pr-6 md:grid md:grid-cols-3",
          "focus:outline-none cursor-pointer"
        )
      }
    >
      {({ active, checked }) => (
        <>
          <span
            className={classNames(
              checked
                ? "bg-primary-600 border-transparent"
                : "bg-white border-gray-300",
              active ? "ring-2 ring-offset-2 ring-primary-500" : "",
              "mr-4 h-4 w-4 mt-0.5 cursor-pointer",
              "rounded-full border flex items-center justify-center"
            )}
            aria-hidden="true"
          >
            <span className="rounded-full bg-white w-1.5 h-1.5" />
          </span>
          <div className=" font-medium text-gray-900 flex-1">{menu.name}</div>
          <DuInfo info={menu.info} />
          <div className="pl-2">
            {!!menu.price && <DuCurrencyFormat value={menu.price} plus />}
          </div>
        </>
      )}
    </RadioGroup.Option>
  );
}

OneOfItem.propTypes = {
  itemTuple: PropTypes.object.isRequired,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

export default OneOfItem;

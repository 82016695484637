import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { MailIcon } from "@heroicons/react/solid";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import { DuButton, DuFormikInput } from "components";
import { post } from "util/api";

const SendReceipt = ({
  venue: { id: venueId },
  orderTracking: { orderIdRemote: orderId, orderUuidClient: uuid },
}) => {
  const [loading, setLoading] = useState(false);

  const sendReceipt = async ({ email }) => {
    setLoading(true);
    try {
      await post("user/venue/$1/order/$2/receipt", [venueId, orderId])
        .data({ uuid, email })
        .on({ status: 401, error: "You cannot access this order" }, () => {
          /* handle error */
        });
      alert("Wir haben Ihnen den Beleg zugeschickt.");
    } catch (e) {
      e && alert(e);
    }
    setLoading(false);
  };

  return (
    <div className="flex-1 flex flex-col justify-center w-full bg-gray-100 pb-4">
      <Formik
        initialValues={{ phone: "" }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Bitte geben Sie eine gültige E-Mail Adresse ein.")
            .required("Bitte geben Sie eine E-Mail Adresse ein."),
        })}
        onSubmit={sendReceipt}
      >
        <Form>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
              Beleg per E-Mail
            </div>
            <div className="px-4 py-5">
              <DuFormikInput
                placeholder="E-Mail Adresse eingeben"
                name="email"
                type={"email"}
                description="Falls Sie einen Beleg benötigen, schicken wir diesen
    gerne an Ihre E-Mail Adresse."
                Icon={MailIcon}
              />
            </div>
            <div className="px-4 pb-5 flex justify-end">
              <DuButton loading={loading} type="submit" label="Abschicken" />
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

SendReceipt.propTypes = {
  venue: PropTypes.object.isRequired,
  orderTracking: PropTypes.object.isRequired,
};

export default connect(({ venue, orderTracking }) => ({
  venue,
  orderTracking,
}))(SendReceipt);

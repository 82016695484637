import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function _AppHeader({ venue, children }) {
  return (
    <div className="flex flex-col bg-white" style={{ minHeight: "100vh" }}>
      {/*app header*/}
      <div className="w-full bg-white h-24 z-20">
        <div className=" flex justify-center pt-5 pb-4 h-24 w-full">
          <img className="h-full" src={venue.logo} alt="Logo" />
        </div>
      </div>

      {/*content*/}
      <>{children}</>
    </div>
  );
}

_AppHeader.propTypes = {
  children: PropTypes.any,
  venue: PropTypes.object,
};

export const AppHeader = connect(({ venue }) => ({ venue }))(_AppHeader);

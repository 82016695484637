import React from "react";
import PropTypes from "prop-types";
import { RadioGroup } from "@headlessui/react";

import ExtraItem from "./ExtraItem";
import OneOfItem from "./OneOfItem";
import {
  createValidOrderSubsectionsFromMenuSection,
  getTupleSubsections,
  getTupleSubsectionItems,
  replaceOrderSubsection,
  replaceOrderSubsectionItem,
  mapOrderSubsection,
} from "@dineup/businesslogic/build/src";

/**
 *
 * @param subItemTuple
 * @param onChange
 * @returns {unknown[]|null}
 * @constructor
 */
const SubsectionItems = ({ subItemTuple, onChange }) => {
  const onChangeSubSection = (index, newItems) => {
    const newSubItemTuple = replaceOrderSubsection(
      index,
      subItemTuple,
      () => newItems
    ).order;

    onChange(newSubItemTuple);
  };
  if (subItemTuple) {
    return getTupleSubsections(subItemTuple).map((subSectionTuple, i) => (
      <SubSection
        key={i}
        onChange={(newSubSection) => onChangeSubSection(i, newSubSection)}
        subSectionTuple={subSectionTuple}
      />
    ));
  }
  return null;
};
SubsectionItems.propTypes = {
  subItemTuple: PropTypes.object,
};

/**
 *
 * @param subSectionTuple
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
export function SubSection({ subSectionTuple: { order, menu }, onChange }) {
  const subsectionTuple = { menu, order: order ?? { items: [] } };
  const itemTuples = getTupleSubsectionItems(subsectionTuple);

  const onSelectOneOf = (index) => {
    const newSubsection = mapOrderSubsection(subsectionTuple, (_, i) =>
      i === index
        ? {
            amount: 1,
            sections: menu.sections
              ? createValidOrderSubsectionsFromMenuSection(menu.sections)
              : null,
          }
        : null
    ).order;

    onChange(newSubsection);
  };

  const onChangeItem = (index, newItem) => {
    const newSubsection = replaceOrderSubsectionItem(
      index,
      subsectionTuple,
      () => newItem
    ).order;
    onChange(newSubsection);
  };

  if (
    subsectionTuple.menu.minExtras === subsectionTuple.menu.maxExtras &&
    subsectionTuple.menu.maxExtras === 1
  ) {
    const activeItemTuples = getTupleSubsectionItems(subsectionTuple);
    return (
      <RadioGroup
        className="mt-2"
        value={itemTuples.reduce(
          (a, itemTuple, i) => (itemTuple.order ? i : a),
          null
        )}
        onChange={onSelectOneOf}
      >
        {subsectionTuple.menu.name && (
          <>
            <h2>{subsectionTuple.menu.name}</h2>
            <RadioGroup.Label className="sr-only">
              {subsectionTuple.menu.name}
            </RadioGroup.Label>
          </>
        )}
        <div className="relative mb-4 mt-2 bg-white rounded-md -space-y-px border">
          {itemTuples.map((itemTuple, idx) => (
            <OneOfItem
              index={idx}
              key={idx}
              itemTuple={itemTuple}
              isFirst={idx === 0}
              isLast={idx === itemTuples.length - 1}
            />
          ))}
        </div>
        {activeItemTuples.map((activeItemTuple, i) =>
          activeItemTuple.order ? (
            <SubsectionItems
              key={i}
              onChange={(item) => onChangeItem(i, item)}
              subItemTuple={activeItemTuple}
            />
          ) : null
        )}
      </RadioGroup>
    );
  } else {
    const activeItemTuples = getTupleSubsectionItems(subsectionTuple);
    return (
      <div className="mt-2">
        {subsectionTuple.menu.name && <h2>{subsectionTuple.menu.name}</h2>}
        <div
          className="relative mb-4 mt-2 bg-white rounded-md
    -space-y-px border flex flex-col items-center "
        >
          {itemTuples.map((itemTuple, i) => (
            <ExtraItem
              onChange={(item) => onChangeItem(i, item)}
              key={i}
              itemTuple={itemTuple}
              isFirst={i === 0}
              isLast={i === itemTuples.length - 1}
            />
          ))}
        </div>
        {activeItemTuples.map((activeItemTuple, i) =>
          (activeItemTuple.order || {}).amount &&
          (activeItemTuple.order || {}).amount !== 0 ? (
            <SubsectionItems
              key={i}
              onChange={(item) => onChangeItem(i, item)}
              subItemTuple={activeItemTuple}
            />
          ) : null
        )}
      </div>
    );
  }
}

SubSection.propTypes = {
  subSectionTuple: PropTypes.object.isRequired,
  isRootFormItem: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

import React from "react";
import { DuBackButton } from "components";
import { FormattedMessage } from "react-intl";

export const Imprint = () => {
  return (
    <div className="flex flex-col m-5 gap-5">
      <div>
        <DuBackButton />
      </div>
      <div>
        <FormattedMessage
          description="Imprint Text"
          defaultMessage="Imprint Das Angebot unter www.spiegel.de besteht aus zwei Teilen: DER SPIEGEL (online) Diensteanbieter für das Angebot unter www.spiegel.de mit Ausnahme sämtlicher Seiten, die 1. als frühere Beiträge aus dem Magazin DER SPIEGEL in der Regel zu Artikelbeginn mit einem Hinweis auf die betreffende Ausgabe oder 2. durch eine Kennzeichnung mit als Teil des Bezahlangebots SPIEGEL+ gekennzeichnet sind, ist die DER SPIEGEL GmbH & Co. KG Ericusspitze 1 20457 Hamburg Tel. 040 3007-0 Fax. 040 3007-2247"
        />
      </div>
    </div>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage as FErrorMessage } from "formik";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

import { classNames } from "util/index";

const DuFormikInput = ({
  label,
  name,
  description,
  cornerHint,
  containerClassName,
  Icon,
  ...props
}) => (
  <div className={containerClassName}>
    <div className="flex justify-between">
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      {cornerHint && (
        <span className="text-sm text-gray-500">{cornerHint}</span>
      )}
    </div>

    <div className="mt-1 relative rounded-md ">
      {Icon && (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      )}
      <Field
        name={name}
        id={name}
        type="text"
        className={classNames(
          "shadow-sm focus:ring-primary-500 focus:border-primary-500",
          "block w-full sm:text-sm border-gray-300 rounded-md",
          Icon ? "pl-10" : ""
        )}
        aria-describedby={name + "-error"}
        {...props}
      />
      <FErrorMessage
        name={name}
        render={() => (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      />
    </div>
    <FErrorMessage
      name={name}
      render={(msg) => (
        <p className="mt-2 text-sm text-red-600" id={name + "-error"}>
          {msg}
        </p>
      )}
    />
    {description && (
      <p className="mt-2 text-sm text-gray-500" id={name + "-description"}>
        {description}
      </p>
    )}
  </div>
);
DuFormikInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  cornerHint: PropTypes.string,
  containerClassName: PropTypes.string,
  Icon: PropTypes.func,
};

export { DuFormikInput };

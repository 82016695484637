import { React } from "react";
import PropTypes from "prop-types";

import { MenuIndexSection } from "./MenuIndexSection";
import { getMenuSections } from "@dineup/businesslogic/build/src/util/getNextMenuLevel";

const MenuStep = ({ menuStep, stepIdx }) => {
  if (!menuStep || !menuStep.sections) {
    return <div />;
  }

  const sections = getMenuSections(menuStep);

  return (
    <div className="flex flex-col bg-white">
      <nav className="h-full" aria-label="Directory">
        {sections.map((section, idx) => (
          <MenuIndexSection
            stepIdx={stepIdx}
            sectionIdx={idx}
            key={idx}
            menuSection={section}
          />
        ))}
      </nav>
    </div>
  );
};

MenuStep.propTypes = {
  stepIdx: PropTypes.number.isRequired,
  menuStep: PropTypes.object.isRequired,
};

export { MenuStep };

import React, { useState } from "react";
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { setOrder } from "redux/orderStore/actions";
import {
  isValidOrder,
  getOrderTotal,
  getOrderItemTotal,
  getTupleItemsPruned,
  replaceOrderItem,
  pruneOrder,
} from "@dineup/businesslogic/build/src";
import { usePaymentMethods } from "const/paymentMethods";
import { storeOrderTracking } from "redux/orderTrackingStore/actions";

import { useSubmitOrder } from "./useSubmitOrder";
import { useStripe } from "./useStripe";

import BasketView from "./BasketView";
import PaymentForm from "./PaymentForm";
import CreditCardForm from "./CreditCardForm";
import Stripe from "./Stripe";
/**
 *
 * @param order
 * @param venue
 * @param setOrder
 * @param storeOrderTracking
 * @returns {JSX.Element}
 * @constructor
 */
function _Basket({ order, venue, setOrder, storeOrderTracking, withCard }) {
  const history = useHistory();
  const { menu, paymentMethods = [] } = venue;
  const displayPaymentMethods = usePaymentMethods().filter(
    ({ identifier }) =>
      paymentMethods.filter(({ name }) => name === identifier).length > 0
  );
  const orderTotal = getOrderTotal(order, menu);

  const { submitOrder, orderNote, setOrderNote, loading, error } =
    useSubmitOrder({ venue, order, storeOrderTracking });

  const {
    stripe,
    walletButtons,
    error: walletButtonError,
    buttonAvailabilityLoading,
  } = useStripe({ venue, orderTotal, submitOrder });
  const useStripeButtons =
    stripe &&
    paymentMethods.filter(({ name }) => name === "stripe").length > 0 &&
    walletButtons;

  const [paypalLoading, setPaypalLoading] = useState(false);
  const submitOrderWithPaymentMethod = async (paymentMethod) => {
    switch (paymentMethod) {
      case "paypal": {
        setPaypalLoading(true);
        const resp = await submitOrder(paymentMethod);
        if (resp) {
          window.location.href = resp.payment.approvalLink;
        } else {
          setPaypalLoading(false);
        }
        break;
      }
      case "stripe": {
        history.push("/basket/card");
        break;
      }
    }
  };

  const requestAmountChange = ({ dishOrderIdx }, newAmount) => {
    const newOrder = pruneOrder(
      replaceOrderItem(dishOrderIdx, { order, menu }, (oldItemTuple) => ({
        ...oldItemTuple.order,
        amount: newAmount,
      }))
    );

    try {
      if (isValidOrder(newOrder, menu)) {
        setOrder(newOrder);
      }
    } catch (e) {
      console.log(e);
      alert(
        "Entschuldigung, es ist ein unerwarteter Fehler aufgetreten! Bitte versuchen Sie es nocheinmal."
      );
    }
  };

  const dishes = getTupleItemsPruned({ menu, order }).map(
    (item, itemOrderIndex) => ({
      ...item,
      stepIdx: item.stepIndex,
      sectionIdx: item.sectionIndex,
      dishIdx: item.itemIndex,
      dishOrderIdx: itemOrderIndex,
      total: getOrderItemTotal(item),
      key: `${item.stepIndex} ${item.sectionIndex} ${item.dishIndex} ${itemOrderIndex}`,
    })
  );
  return (
    <BasketView
      dishes={dishes}
      requestAmountChange={requestAmountChange}
      isOrderEmpty={dishes.length === 0}
      orderNote={orderNote}
      setOrderNote={setOrderNote}
      orderTotal={orderTotal}
    >
      {withCard ? (
        <CreditCardForm
          error={error}
          theme={venue.colors}
          submitOrder={submitOrder}
        />
      ) : (
        <PaymentForm
          showCardInput={withCard}
          paymentMethods={displayPaymentMethods}
          submitOrder={submitOrderWithPaymentMethod}
          isPending={loading || paypalLoading || buttonAvailabilityLoading}
          error={error}
          useStripeButtons={useStripeButtons}
          walletButtons={{ paymentRequest: walletButtons }}
          walletButtonError={walletButtonError}
        />
      )}
    </BasketView>
  );
}

_Basket.propTypes = {
  order: PropTypes.object.isRequired,
  venue: PropTypes.object.isRequired,
  setOrder: PropTypes.func.isRequired,
  storeOrderTracking: PropTypes.func.isRequired,
  withCard: PropTypes.bool,
};

const BasketGate = ({
  order,
  venue,
  setOrder,
  storeOrderTracking,
  withCard,
}) => {
  const history = useHistory();
  if (!venue || !venue.menu) {
    history.push("/");
    return <div />;
  }

  return (
    <Stripe venue={venue}>
      <_Basket
        order={order}
        venue={venue}
        setOrder={setOrder}
        storeOrderTracking={storeOrderTracking}
        withCard={withCard}
      />
    </Stripe>
  );
};
BasketGate.propTypes = {
  order: PropTypes.object.isRequired,
  venue: PropTypes.object.isRequired,
  setOrder: PropTypes.func.isRequired,
  storeOrderTracking: PropTypes.func.isRequired,
  withCard: PropTypes.bool,
};

export const Basket = connect(
  ({ order, venue }) => ({
    order,
    venue,
  }),
  {
    setOrder,
    storeOrderTracking,
  }
)(BasketGate);

import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { DuButton } from "components";

const DuBackButton = ({ children, ...args }) => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  return (
    <DuButton onClick={() => goBack()} {...args}>
      {children || "Zurück"}
    </DuButton>
  );
};

DuBackButton.propTypes = {
  children: PropTypes.node,
};

export { DuBackButton };

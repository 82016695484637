import React from "react";
import { useHistory } from "react-router-dom";

import { DuButton, DuInfoWell } from "components";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const PaymentCancel = () => {
  const history = useHistory();

  const redirectToBasket = () => {
    history.push("/basket");
  };

  return (
    <div className="absolute bottom-0 top-0 full flex flex-col justify-evenly p-5">
      <DuInfoWell>
        <div className="m-5">Der Bezahlvorgang wurde abgebrochen!</div>

        <DuButton onClick={redirectToBasket}>Zurück zum Warenkorb</DuButton>
      </DuInfoWell>
    </div>
  );
};

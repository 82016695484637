import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const ErrorMessage = ({ error }) => {
  switch (error) {
    case "Menu version too old":
      return (
        <FormattedMessage
          description="Ihre Bestellung wurde vor zu langer Zeit begonnen. Bitte scannen Sie den QR-Code und geben Ihre Bestellung erneut auf."
          defaultMessage="Payment error message"
        />
      );
    case "Walletpayment failed":
      return (
        <FormattedMessage
          description="Ihre Zahlung konnte nicht durchfgeführt werden. Bitte wählen Sie ein anderes Zahlungsmittel und versuchen Sie es erneut."
          defaultMessage="Wallet payment error message"
        />
      );
    case "Card could not be confirmed":
      return (
        <FormattedMessage
          description="Ihre Zahlung konnte nicht durchgeführt werden. Bitte versuchen Sie es erneut."
          defaultMessage="Wallet payment card error message"
        />
      );
    default:
      return <p>{error}</p>;
  }
};
ErrorMessage.propTypes = {
  error: PropTypes.string,
};

export { ErrorMessage };

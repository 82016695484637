import React from "react";
import PropTypes from "prop-types";

import { classNames } from "util/index";

MenuStepNav.propTypes = {
  onSelection: PropTypes.func,
  menuSteps: PropTypes.any,
  currentStep: PropTypes.number.isRequired,
};

export function MenuStepNav({ onSelection, currentStep, ...props }) {
  const menuSteps = props.menuSteps;

  return (
    <nav
      aria-label="Progress"
      className="sticky flex bg-white h-16 top-0 z-30 shadow-lg
 w-full bg-white-center justify-items-center"
    >
      <ol
        className={classNames(
          "w-full flex items-center",
          menuSteps.length < 3
            ? " justify-center px-4 "
            : "justify-start overflow-y-scroll  pl-4"
        )}
      >
        {menuSteps.map((step, i) => (
          <button
            key={i}
            onClick={() => {
              onSelection(i);
            }}
            className={classNames(
              "border border-1 border-primary-600",
              "compact-btn w-full rounded-none ",
              "h-8",
              i === 0 && "rounded-l-lg",
              i === menuSteps.length - 1 ? "rounded-r-lg" : "border-r-0",
              currentStep === i
                ? "bg-primary-600 text-white"
                : "text-primary-600"
            )}
          >
            {step.name}
          </button>
        ))}
      </ol>
    </nav>
  );
}

import React from "react";
import * as PropTypes from "prop-types";

import { classNames } from "util/index";

export const DuInfoWell = ({ children, className, ...props }) => {
  return (
    <div
      className={classNames(
        className,
        "shadow rounded-lg bg-white-op px-4 py-4 text-center bg-opacity-80"
      )}
      {...props}
    >
      {children}
    </div>
  );
};

DuInfoWell.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

import React from "react";
import PropTypes from "prop-types";
import { CheckIcon } from "@heroicons/react/solid";

import { classNames } from "util/index";

OrderStatusProgressSegment.propTypes = {
  step: PropTypes.object,
  current: PropTypes.bool,
  complete: PropTypes.bool,
};

export function OrderStatusProgressSegment({ current, complete, step }) {
  if (!step) {
    return <div />;
  }
  return (
    <a
      href={step.href}
      className="relative flex items-start group items-center"
      aria-current="step"
    >
      <span className="h-9 flex items-center" aria-hidden="true">
        {complete ? (
          <span className="h-9 flex items-center">
            <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-primary-600 rounded-full group-hover:bg-primary-800">
              <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
            </span>
          </span>
        ) : (
          <span
            className={classNames(
              "relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 rounded-full",
              "border-gray-300 group-hover:border-gray-400"
            )}
          >
            <span
              className={classNames(
                "",
                "bg-transparent group-hover:bg-gray-300",
                "h-2.5 w-2.5 rounded-full"
              )}
            />
          </span>
        )}
      </span>
      <span className="ml-4 min-w-0 flex flex-col ">
        <span
          className={classNames(
            "text-xs font-semibold tracking-wide uppercase",
            current ? "text-primary-600" : "text-gray-500"
          )}
        >
          {step.stateTitleText}
        </span>
        {step.stateDescription && (
          <span className="text-sm text-gray-500">{step.stateDescription}</span>
        )}
      </span>
    </a>
  );
}

import React, { useState } from "react";
import { QrReader } from "@blackbox-vision/react-qr-reader";

import { DuBackButton } from "components";

export const QrScanner = () => {
  const [isScanning, setIsScanning] = useState(true);

  const onScan = (text) => {
    if (text && isScanning) {
      const ms = /^https:\/\/[^/]*dineup\.app\/.*$/.exec(text);
      if (ms) {
        setIsScanning(false);
        location = ms[0];
      } else {
        alert("Der QR-Code scheint beschädigt zu sein.");
      }
    }
  };

  return (
    <div>
      <DuBackButton className="m-5" />
      <h1 className="p-5">Bitte visieren Sie den QR Code mit der Kamera an.</h1>
      <div
        className="QrScanner h-full flex center items-center
 pointer-events-none justify-center"
      >
        <QrReader
          constraints={{ facingMode: "environment" }}
          className="w-full p-5 "
          facingMode={"environment"}
          delay={500}
          onResult={onScan}
          onError={(e) => alert(e)}
        />
      </div>
    </div>
  );
};

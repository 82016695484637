import "./_OrderStatusProgressVertical.scss";
import React from "react";
import PropTypes from "prop-types";

import { OrderStatusProgressSegment } from "./OrderStatusProgressSegment";
import { classNames } from "util/index";

OrderStatusProgressVertical.propTypes = {
  progressStates: PropTypes.arrayOf(
    PropTypes.shape({
      stateTitleText: PropTypes.string,
      stateDescription: PropTypes.string,
      status: PropTypes.string,
    })
  ),
  currentStateIdentifier: PropTypes.number,
};

//TD: move, use in sub-comps
export const OrderStatusStrings = {
  Current: "current",
  Complete: "complete",
};

export function OrderStatusProgressVertical(props) {
  const { progressStates, currentStateIdentifier } = props;

  const steps = progressStates.map((step, i) => ({
    ...step,
    status:
      i === currentStateIdentifier
        ? OrderStatusStrings.Current
        : i < currentStateIdentifier
        ? OrderStatusStrings.Complete
        : "",
  }));

  function isLastProgressSegment(stepIdx) {
    return stepIdx === steps.length - 1;
  }

  return (
    <div className="OrderStatusProgressVertical">
      <nav aria-label="Progress">
        <ol className="overflow-hidden">
          {steps.map((step = {}, stepIdx) => (
            <li
              key={step.stateTitleText}
              className={classNames(
                !isLastProgressSegment(stepIdx) ? "pb-10" : "",
                "relative"
              )}
            >
              {!isLastProgressSegment(stepIdx) ? (
                <div
                  className={classNames(
                    "-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full",
                    step.status === "complete"
                      ? "bg-primary-600"
                      : "bg-gray-300"
                  )}
                  aria-hidden="true"
                />
              ) : null}
              <OrderStatusProgressSegment
                step={step}
                current={step.status === "current"}
                complete={step.status === "complete"}
              />
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";

import { useRedirectOnActiveOrder } from "util/hooks";
import { AppHeader, MenuShell } from "view-shells";
import {
  QrScanner,
  DetectVenueCode,
  DineupLanding,
  VenueWelcome,
  Basket,
  PaymentReturn,
  PaymentCancel,
  AddContactInfo,
  OrderConfirmation,
  MenuDishDetails,
  Legal,
  Imprint,
} from "views";

/**
 *
 * @param children
 * @param venue
 * @param order
 * @returns {*}
 * @private
 */
const _RedirectToOrderConfirmation = ({ children, venue, order }) => {
  useRedirectOnActiveOrder(venue, order);
  return children;
};
_RedirectToOrderConfirmation.propTypes = {
  children: PropTypes.node.isRequired,
  order: PropTypes.object.isRequired,
  venue: PropTypes.object.isRequired,
};
const RedirectToOrderConfirmation = connect(({ order, venue }) => ({
  order,
  venue,
}))(_RedirectToOrderConfirmation);

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export function AppContentPane() {
  return (
    <RedirectToOrderConfirmation>
      <Switch>
        <Route path="/confirmation">
          <AppHeader className="sticky">
            <OrderConfirmation />
          </AppHeader>
        </Route>

        <Route path="/" exact>
          <Redirect to="/dineup-landing" />
        </Route>

        <Route path="/code/">
          <DetectVenueCode />
        </Route>

        <Route path="/venue-welcome">
          <VenueWelcome />
        </Route>

        <Route path="/qr">
          <AppHeader className="sticky">
            <QrScanner />
          </AppHeader>
        </Route>

        <Route path="/dineup-landing">
          <DineupLanding />
        </Route>

        <Route path="/order" exact>
          <Redirect to="/order/0" />
        </Route>

        <Route path="/order/:id">
          <AppHeader className="sticky">
            <MenuShell />
          </AppHeader>
        </Route>

        <Route path="/dish/:stepIndex/:sectionIndex/:itemIndex">
          <MenuDishDetails />
        </Route>

        <Route path="/basket/card">
          <AppHeader className="sticky">
            <Basket withCard />
          </AppHeader>
        </Route>

        <Route path="/basket">
          <AppHeader className="sticky">
            <Basket />
          </AppHeader>
        </Route>

        <Route path="/payment-return">
          <PaymentReturn />
        </Route>

        <Route path="/payment-cancel">
          <AppHeader className="sticky">
            <PaymentCancel />
          </AppHeader>
        </Route>

        <Route path="/contact">
          <AppHeader className="sticky">
            <AddContactInfo />
          </AppHeader>
        </Route>

        <Route path="/legal">
          <AppHeader className="sticky">
            <Legal />
          </AppHeader>
        </Route>

        <Route path="/imprint">
          <AppHeader className="sticky">
            <Imprint />
          </AppHeader>
        </Route>
      </Switch>
    </RedirectToOrderConfirmation>
  );
}
